import React from "react";
import {
  FaGithub,
  FaFacebookF,
  FaLinkedinIn,
  FaDev,
  FaDiscord,
} from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { SiHashnode } from "react-icons/si";

const HeaderSocials = () => {
  return (
    <div className="home__socials">
      <a
        href="https://www.github.com/usman-akram-dev"
        className="home__social-link"
        target="_blank"
        rel="noreferrer"
      >
        <FaGithub />
      </a>

      <a
        href="https://hashnode.com/@codewithhustler"
        className="home__social-link"
        target="_blank"
        rel="noreferrer"
      >
        <SiHashnode />
      </a>

      <a
        href="https://dev.to/codewithhustler"
        className="home__social-link"
        target="_blank"
        rel="noreferrer"
      >
        <FaDev />
      </a>

      <a
        href="https://www.linkedin.com/in/usman2000/"
        className="home__social-link"
        target="_blank"
        rel="noreferrer"
      >
        <FaLinkedinIn />
      </a>

      <a
        href="https://wwww.facebook.com/i.usman.visionary"
        className="home__social-link"
        target="_blank"
        rel="noreferrer"
      >
        <FaFacebookF />
      </a>

      <a
        href="https://discordapp.com/users/cypher5518"
        className="home__social-link"
        target="_blank"
        rel="noreferrer"
      >
        <FaDiscord />
      </a>

      <a
        href="https://www.twitter.com/codewithhustler"
        className="home__social-link"
        target="__blank"
        rel="noreferrer"
      >
        <FaSquareXTwitter />
      </a>
    </div>
  );
};

export default HeaderSocials;
