import React from "react";
import "./Services.css";
import Image1 from "../../assets/service-1.svg";
import Image2 from "../../assets/blockchain.png";
import Image3 from "../../assets/devops.png";
import Image4 from "../../assets/database.png";
import Image5 from "../../assets/generativeai.png";
import Image6 from "../../assets/archi.png";

const data = [
  {
    id: 1,
    image: Image1,
    title: "Full Stack Development",
    description:
      "Crafting seamless, cross-platform experiences with a knack for responsive design.",
  },
  {
    id: 2,
    image: Image2,
    title: "Blockchain Development",
    description:
      "Shaping the digital horizon with advanced and secure blockchain solutions.",
  },
  {
    id: 3,
    image: Image3,
    title: "DevOps and Cloud Services",
    description:
      "Optimizing applications for peak performance and scalability with a tech-savvy touch.",
  },
  {
    id: 4,
    image: Image4,
    title: "Database Development",
    description:
      "Data-driven websites and apps, designed for speed and user satisfaction.",
  },
  {
    id: 5,
    image: Image5,
    title: "Generative AI",
    description: "Unlocking innovation through the magic of Generative AI.",
  },
  {
    id: 6,
    image: Image6,
    title: "Software Architecture Development",
    description:
      "Building top-tier software solutions with impeccable design and outstanding performance across all platforms.",
  },
];

const Services = () => {
  return (
    <section className="services container section" id="services">
      <h2 className="section__title">Expertise</h2>

      <div className="services__container grid">
        {data.map(({ id, image, title, description }) => {
          return (
            <div className="services__card" key={id}>
              <div className="services__content">
                <img src={image} alt="" className="services__img" />

                <h3 className="services__title">{title}</h3>
                <p className="services__description">{description}</p>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Services;
