import React from "react";
import "./About.css";
import Image from "../../assets/usman.png";
import Resume from "../../assets/Usman-Akram.pdf";
import AboutBox from "./AboutBox";

const About = () => {
  const downloadResume = async () => {
    window.open(Resume, "_blank");
  };

  const chatWithCV = async () => {
    window.open("https://chat-with-usman-cv.vercel.app/", "_blank");
  };

  return (
    <section className="about container section" id="about">
      <h2 className="section__title">About Me </h2>

      <div className="about__container grid">
        <img src={Image} alt="" className="about__img" width="400" />

        <div className="about__data grid">
          <div className="about__info">
            <p className="about__description">
              Hey there, I'm Usman, a tech enthusiast and dedicated hustler
              based in Pakistan. My journey in the world of technology has been
              an exciting and rewarding one. I'm a Software Engineer with a
              passion for crafting innovative solutions. I'm committed to
              continuous growth and learning, and I thrive on the challenges and
              opportunities that the tech industry offers.
              <br />
              <br />
              Here are a few technologies I’ve been working with recently:
            </p>
            <ul className="about__list">
              <li>JavaScript (ES6+)</li>
              <li>TypeScript</li>
              <li>React</li>
              <li>Material UI</li>
              <li>Tailwind CSS</li>
              <li>Bootstrap</li>
              <li>Node.js</li>
              <li>Postgres SQL</li>
              <li>NextJS</li>
              <li>NestJS</li>
              <li>MongoDB</li>
              <li>GraphQL</li>
              <li>Solidity</li>
              <li>Ethereum</li>
              <li>ICP (Internet Computer)</li>
              <li>Venom network</li>
              <li>Docker</li>
              <li>Ansible</li>
              <li>Cloud Computing</li>
              <li>AWS (Amazon Web Services)</li>
              <li>Generative AI</li>
              <li>Langchain</li>
            </ul>
            <div className="about__cv__buttons">
            <button className="btn" onClick={downloadResume}>
              Download CV
            </button>
            <button className="btn" onClick={chatWithCV}>
              Chat With CV
            </button>
            </div>
          </div>

          {/* <div className="about__skills grid">
            <div className="skills__data">
              <div className="skills__titles">
                <h3 className="skills__name">Development</h3>
                <span className="skills__number">90%</span>
              </div>

              <div className="skills__bar">
                <span className="skills__percentage development"></span>
              </div>
            </div>

            <div className="skills__data">
              <div className="skills__titles">
                <h3 className="skills__name">UI/UX Design</h3>
                <span className="skills__number">80%</span>
              </div>

              <div className="skills__bar">
                <span className="skills__percentage ui__design"></span>
              </div>
            </div>

            <div className="skills__data">
              <div className="skills__titles">
                <h3 className="skills__name">Photography</h3>
                <span className="skills__number">60%</span>
              </div>

              <div className="skills__bar">
                <span className="skills__percentage photography"></span>
              </div>
           
            </div>
          </div> */}
        </div>
      </div>

      <AboutBox />
    </section>
  );
};

export default About;
