import React from "react";
import "./Testimonials.css";
import Male from "../../assets/avatar-4.svg";
import Female from "../../assets/female.png";

import { Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";

const Testimonials = () => {
  const data = [
    {
      id: 1,
      image: Male,
      title: "Sabir Hafeez",
      subtitle: "Co-Founder @ Nascent Innovations",
      comment:
        "I was impressed by his work ethic and managerial skills. He was a smart worker with a great sense of humor and commitment towards his duty. He was always ready to go the extra mile to provide high-quality of work.",
    },
    {
      id: 2,
      image: Male,
      title: "Taha Wakeel",
      subtitle: "Senior Software Engineer @ HBL",
      comment:
        "Muhammad Usman was one of the most prominent and competent students in my class while I was taking the Mobile Application Development course at DHA Suffa University. Throughout the semester I found him the most disciplined,responsible and dedicated student. The ability of picking up the core concept, compiling the business logic and thirst to learn in the quickest way possible stands him out among others. I would highly rate him due to his technical and soft skills.",
    },
    {
      id: 3,
      image: Female,
      title: "Bushra Burney",
      subtitle: "DevOps Engineer @ Contour Software",
      comment:
        "Usman is an exceptional student with both a strong grasp on his studies and as a class representative. He has always been helpful in managing class schedules and assisting me during class lectures. He is very skilled when it comes to programming in different languages using different tools so he can adapt to any product development environment due to his vast skillset and ability to adapt and learn. I recommend Usman to any organisation looking for a reliable and skilled candidate for any position related to computer science.",
    },
    {
      id: 4,
      image: Male,
      title: "Ali Asadullah Shah",
      subtitle: "SQA Analyst @ Contour Software",
      comment:
        "Usman has a wide range of experience in software development and the AI tech stack. He can turn ideas into reality, has excellent coding skills, and picks things up extremely quickly. In the project we were both working on, he was the lone developer, and he always met his deadlines! He is currently an absolute gem at his level!",
    },

    {
      id: 5,
      image: Male,
      title: "Syed Mustafa Naqvi",
      subtitle: "DevOps Engineer @ Tekrevol",
      comment:
        "Highly recommended, Passionate in his field, hard worker, quick learner and deliver work with perfection and honesty.",
    },

    {
      id: 6,
      image: Male,
      title: "Rehan Ul Haq",
      subtitle: "Associate Software Engineer @ Paysys Labs",
      comment:
        "I highly recommend Usman as a software engineer. He is an outstanding professional who possesses a combination of technical expertise, teamwork, and communication skills that make him a valuable asset to any organization.",
    },

    {
      id: 7,
      image: Female,
      title: "Dr. Huma Jamshed",
      subtitle: "Assistant Professor & Director IT @ DHA Suffa University",
      comment: "Usman is Dedicated and hardworking. have team lead qualities.",
    },

    {
      id: 8,
      image: Female,
      title: "Urooj Waheed",
      subtitle: "Senior Lecturer @ DHA Suffa University",
      comment:
        "Usman's expertise in full stack development, blockchain, cloud computing, and DevOps, combined with his quick learning ability, leadership skills, and admirable qualities, make him an invaluable addition to any organization.",
    },

    {
      id: 9,
      image: Female,
      title: "Sidrah Abdullah",
      subtitle: "Technical Content Writer @ Baeldung",
      comment:
        "I strongly recommend Usman for his exceptional work on his final year project. He successfully implemented Smart Contracts for escrow functionality in Enterprise Blockchain using Hyperledger Besu and integrated web3 using a Quorum network. Usman displayed dedication, independence, and a proactive approach, consistently meeting deadlines and enhancing his application based on feedback. His strong communication and collaborative skills make him a valuable asset to any organization.",
    },

    {
      id: 10,
      image: Male,
      title: "Noor Ali",
      subtitle: "Senior Lecturer @ DHA Suffa Univesity",
      comment:
        "Muhammad Usman was my student during his 8th semester at DHA Suffa University. During the studies, I found Usman to be very punctual, hard working and obedient. He ranked in the top 10% of that class. Usman possess great knowledge and experience in front-end and back-end development. I see a great potential in Usman and wish him all the success.",
    },
  ];

  return (
    <section className="testimonials container section">
      <h2 className="section__title">Recommendations</h2>

      <Swiper
        className="testimonial__container grid"
        modules={[Autoplay, Pagination]}
        spaceBetween={30}
        slidesPerView={1}
        loop={true}
        grabCursor={true}
        pagination={{ clickable: true }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
      >
        {data.map(({ id, image, title, subtitle, comment }) => {
          return (
            <SwiperSlide className="testimonial__item" key={id}>
              <div className="thumb">
                <img src={image} alt="" />
              </div>
              <h3 className="testimonial__title">{title}</h3>
              <span className="subtitle">{subtitle}</span>
              <div className="comment">{comment}</div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </section>
  );
};

export default Testimonials;
