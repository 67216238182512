import Work1 from "../../assets/1tensolutions.png";
import Work1Logo from "../../assets/1tensolutions-logo.png";
import Work2 from "../../assets/jelly.png";
import Work2Logo from "../../assets/jelly-logo.png";
import Work3 from "../../assets/escrow-bridge.png";
import Work3Logo from "../../assets/escrow-bridge.jpg";
import Work4 from "../../assets/puchooo.png";
import Work4Logo from "../../assets/puchooo-logo.png";
import Work5 from "../../assets/Ansible-Docker-Container-Provisioning.png";
import Work6 from "../../assets/aws-1.png";
import Work7 from "../../assets/aws-2.png";

const Menu = [
  {
    id: 1,
    image: Work1,
    logo: Work1Logo,
    title: "1 Ten Solutions Website",
    category: ["Frontend", "React", "TailwindCSS", "MaterialUI"],
    company: "1 Ten Solutions",
    contributions: [
      "1 Ten Solutions Website Development, Frontend Development, Backend Development, Website Deployment",
    ],
    description:
      "1 Ten Solutions! a software company that specializes in designing and developing cutting-edge websites, software, and mobile applications.",
    url: "https://1tensolutions.com",
    showLogo: true,
  },
  {
    id: 2,
    image: Work2,
    logo: Work2Logo,
    title: "Jelly NFT Marketplace",
    category: ["Backend, Blockchain"],
    company: "IrenicTech",
    contributions: ["Backend Development, Smart Contract Development"],
    description:
      "Sweet NFT Tools & Marketplace Infrastructure ✨ From an open marketplace protocol to curator UIs & tools of all flavors 🍓🥭🥝 Built on the Internet Computer Blockchain",
    url: "https://jelly.xyz/",
    repositoryUrl: "https://github.com/Psychedelic/nft-marketplace",
    showLogo: true,
  },
  {
    id: 3,
    image: Work3,
    logo: Work3Logo,
    title: "Escrow Bridge",
    category: ["FYP, Frontend, Backend, Blockchain"],
    company: "Individual",
    contributions: [
      "Architecture Development, Frontend Development, Backend Development, Smart Contract Development, Smart Contract Integration, Smart Contract Deployment On Blockchain ",
    ],
    description:
      "EscrowBridge, the future of secure and reliable online transactions! The platform is designed to counter fraud and ensure both buyers and sellers can engage in online transactions with confidence. With EscrowBridge, you can bid farewell to uncertainties and embrace a secure shopping experience like never before.",
    url: "http://escrowbridge.pk/",
    showLogo: true,
  },
  {
    id: 4,
    image: Work4,
    logo: Work4Logo,
    title: "PuchOOO",
    category: ["Frontend, Backend, Blockchain"],
    company: "IrenicTech",
    contributions: [
      "Architecture Development, Frontend Development, Backend Development, Smart Contract Development, Smart Contract Integration, Smart Contract Deployment On Blockchain ",
    ],
    description:
      "PuchOOO, the SocialFi app revolutionizing digital interactions. Explore diverse themes, build your reputation, and collect Irenic (IR) tokens. Join a self-moderated community where quality content is rewarded. Mint and trade NFTs on our marketplace, leveraging your digital assets for perks. Network, collaborate, and experience the future of SocialFi",
    url: "https://puchooo.com",
    showLogo: true,
  },
  {
    id: 5,
    image: Work5,
    title: "Ansible Docker Container Provisioning",
    category: ["DevOps, Ansible, Docker"],
    company: "Individual",
    contributions: [
      "Demonstrated the use of Ansible for automating the provisioning and configuration of containers in a project",
      "Developed Ansible playbooks to seamlessly set up a PostgreSQL database, validate Apache availability, and execute Git operations",
      "Streamlined application management and deployment by leveraging Ansible's declarative language, enabling the definition of infrastructure as code",
      "Showcased the capabilities of Ansible in simplifying the orchestration of complex tasks, enhancing efficiency in containerized environments.",
    ],
    description:
      "This project demonstrates the use of Ansible to provision and configure three containers using Docker. The containers are provisioned with specific configurations: one for PostgreSQL, one for Apache, and one for Git. The objective is to showcase the power and capabilities of Ansible in automating the setup and configuration of containers.",
    repositoryUrl:
      "https://github.com/usman-akram-dev/Ansible-Docker-Container-Provisioning",
    showLogo: false,
  },
  {
    id: 6,
    image: Work6,
    title: "aws-serverless-example",
    category: ["AWS, API Gateway, Lambda, RDS Proxy"],
    company: "Individual",
    contributions: [
      "Designed a serverless architecture utilizing API Gateway as the entry point for requests, handling routing, authentication, and authorization",
      "Implemented AWS Lambda functions to execute the business logic of the application, facilitating event-driven code execution",
      "Integrated Amazon RDS Proxy as a performance-optimizing intermediary between Lambda functions and the RDS Aurora database",
      "Established Amazon RDS Aurora as the fully managed relational database, ensuring high availability, durability, and scalability for the serverless application's data",
      "Orchestrated the flow where API Gateway directs requests to Lambda functions, which interact with RDS Proxy for optimized database connections to RDS Aurora",
      "Created a scalable, cost-effective, and low-maintenance serverless stack, eliminating the need for traditional server management.",
    ],
    description:
      "API Gateway directs requests to Lambda functions, which interact with RDS Proxy for optimized connections to RDS Aurora. This serverless setup provides scalability, cost-effectiveness, and low maintenance, eliminating the need for traditional server management.",
    repositoryUrl: "https://github.com/usman-akram-dev/aws-serverless-example",
    showLogo: false,
  },
  {
    id: 7,
    image: Work7,
    title: "aws-containers-example",
    category: ["AWS, ECS Cluster, ALB, RDS Aurora"],
    company: "Individual",
    contributions: [
      "Architected and implemented a containerized application stack, integrating an Application Load Balancer for optimal traffic distribution, an ECS Cluster for container management, and a scalable API service container",
      "Orchestrated the deployment process to ensure high availability, fault tolerance, and auto-scaling capabilities",
      "Established a relational database solution using RDS Aurora, ensuring data persistence and integrity for the API.",
    ],
    description:
      "The application can efficiently handle incoming requests through the Application Gateway, which distributes traffic to the API service containers hosted on the ECS Cluster. The API service interacts with the RDS Aurora database to store and retrieve data, providing a robust and scalable foundation for the containerized application stack.",
    repositoryUrl: "https://github.com/usman-akram-dev/aws-containers-example",
    showLogo: false,
  },
];

export default Menu;
