const WorkExperience = [
  {
    id: 1,
    title: "Software Engineer",
    company: "IrenicTech",
    yearsActive: "October 2021 - Present",
    information: [
      "Engaged with various technologies in web app development, game development, blockchain development, and software architecture design.",
      "Front-end work involved ReactJS and Svelte, while back-end tasks employed NodeJS and GO.",
      "In blockchain development, tasks encompassed writing 100% efficient smart contracts using Solidity for Ethereum and Polygon networks, as well as using Motoko and Rust for the IC (Internet Computer) network, and TVM Solidity for Venom network.",
      "For blockchain development, relied on development tools like Hardhat and Truffle to reduce 50% deployment time.",
      "Additionally, have experience with IPFS (InterPlanetary File System) and familiarity with LangChain.",
      "Utilized Docker for containerization and AWS (Amazon Web Services) for cloud services to maintain 0% downtime.",
      "Also, have hands-on experience with Firebase for web and mobile app development, where sending push notifications only to 100% real users.",
    ],
  },
  {
    id: 2,
    title: "Campus Ambassador",
    company: "10 Pearls League Of Champs",
    yearsActive: "Jan 2022 - Jan 2023",
    information: [
      "Empowered to ideate, develop and execute tailormade plans to market 10Pearls, empower students at DHA Suffa University and transform lives!",
    ],
  },
  {
    id: 3,
    title: "Software Engineer Intern",
    company: "1 Ten Solutions",
    yearsActive: "August 2021 - November 2021",
    information: [
      "Developed and deployed a website using ReactJS, Tailwind CSS, Material UI, and EmailJS.",
      "Linked the project's repository for automatic deployment to production.",
      "Demonstrated skills in web deployment.",
      "Utilized continuous integration/continuous delivery (CI/CD) practices.",
      "Developed web applications using the MERN Stack.",
      "Handled DevOps tasks.",
    ],
  },
];

export default WorkExperience;
