import React from "react";
import "./Home.css";
import Me from "../../assets/usman.png";
import HeaderSocials from "./HeaderSocials";
import ScrollDown from "./ScrollDown";
import Shapes from "./Shapes";
import Typewriter from "typewriter-effect";
import bg from "../../assets/bg.gif";

const Home = () => {
  const backgroundImageStyle = {
    background: `url(${bg})`,
    backgroundSize: "100% 100%",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  };

  return (
    <section className="home" id="home" style={backgroundImageStyle}>
      <div className="intro">
        <div className="home__img-container">
          {/* <img src={test} alt="" className="home__img" width="120" /> */}
        </div>
        <h2 className="home__greet">Hello! 👋 I'm</h2>
        <h1 className="home__name">Muhammad Usman Akram</h1>
        <h3 className="home__role">
          <Typewriter
            options={{
              strings: [
                "Software Engineer",
                "Tech Enthusiast",
                "Software Alchemist",
              ],
              autoStart: true,
              loop: true,
            }}
          />
        </h3>

        <HeaderSocials />

        {/* <a href="#contact" className="btn">
          {" "}
          Hire Me
        </a> */}

        <ScrollDown />
      </div>

      {/* <Shapes /> */}
    </section>
  );
};

export default Home;
