const EducationHistory = [
  {
    id: 1,
    title: "Bachelors Of Science In Computer Science",
    company: "DHA Suffa University",
    yearsActive: "September 2019 - August 2023",
    information: [
      " Cloud Computing ",
      " DevOps",
      " Mobile App Devlopment ",
      " SQA ",
      " Machine Learning ",
      " Artificial Intelligence ",
      " Information Security ",
      " Data Communication and Computer Networks ",
      " Operating Systems",
      " Design and Analysis of Algorithms ",
      " Object Oriented Programming ",
      " Programming Fundamentals ",
      " Data Structures and Algorithms ",
      " Advance Database Management Systems ",
    ],
  },
];

export default EducationHistory;
