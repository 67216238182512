// Modal.js
import React from "react";
import "./Modal.css";
import { RiGithubLine } from "react-icons/ri";

const Modal = ({ closeModal, project }) => {
  return (
    <div className="modal-overlay" onClick={closeModal}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="modal-close" onClick={closeModal}>
          &times;
        </button>
        <img
          src={project.showLogo ? project.logo : project.image}
          alt={project.title}
          className="modal-img"
        />
        <h2 className="modal-title">{!project.showLogo && project.title}</h2>
        <ul className="modal-description">
          {project.description.split(". ").map((index) => (
            <li>{index} .</li>
          ))}
        </ul>
        <p>
          <b>Contributions: </b>
          {project.contributions.join(", ")}
        </p>
        <div className="modal-buttons">
          {project.url && (
            <a
              href={project.url}
              target="_blank"
              rel="noreferrer"
              className="modal-button btn"
            >
              View Project
            </a>
          )}
          {project.repositoryUrl && (
            <a
              href={project.repositoryUrl}
              target="_blank"
              rel="noreferrer"
              className="modal-repo-button btn"
            >
              <RiGithubLine className="github-icon" /> View Repository
            </a>
          )}
          <button className="modal-close-button btn" onClick={closeModal}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
