import Cert1 from "../../assets/BeginningAWS.png";
import Cert2 from "../../assets/git.png";
import Cert3 from "../../assets/BasicsofKubernetes.png";
import Cert4 from "../../assets/Front-EndWebDevelopmentwithReact.jpg";
import Cert5 from "../../assets/Generative-AI.png";
import Cert6 from "../../assets/Cloud-Solution-Architecture-Usman.jpg";
import Cert7 from "../../assets/smart-contracts.png";
import Cert8 from "../../assets/dapps.png";
import Cert9 from "../../assets/blockchain-platforms.png";
import Cert10 from "../../assets/EthereumBlockchainandDApps​.png";
import Cert11 from "../../assets/blockchain-specialization.png";
import Cert12 from "../../assets/blockchain-basics.png";

const Menu = [
  {
    id: 1,
    image: Cert11,
    title: "Blockchain Specialization​",
    category: ["Blockchain"],
    url: "https://coursera.org/share/b64e79db5d4ed069a7b09195c5223477",
  },

  {
    id: 2,
    image: Cert12,
    title: "Blockchain Basics​",
    category: ["Blockchain"],
    url: "https://coursera.org/share/a8e806370dc2f8b1e230d7ce4fbb39d8",
  },
  {
    id: 3,
    image: Cert7,
    title: "Smart Contracts",
    category: ["Blockchain"],
    url: "https://coursera.org/share/fad5d3ab3ace1a40302716fa95b0a436",
  },
  {
    id: 4,
    image: Cert8,
    title: "Decentralized Applications (DApps)",
    category: ["Blockchain"],
    url: "https://coursera.org/share/60057a5631af7f67027eaf41faa8c543",
  },
  {
    id: 5,
    image: Cert9,
    title: "Blockchain Platforms",
    category: ["Blockchain"],
    url: "https://coursera.org/share/ea2d7c46409253ca4a90b3a0c94392eb",
  },
  {
    id: 6,
    image: Cert10,
    title: "Ethereum Blockchain and DApps​",
    category: ["Blockchain"],
    url: "https://10pearlsuniversity.org/view-certificate?cid=10PUC-742801ba4b94752031319815f70780a335f9c4852c49047a1806943",
  },
  {
    id: 7,
    image: Cert5,
    title: "Generative AI with Large Language Models",
    category: ["Generative AI"],
    url: "https://coursera.org/share/c9491bf1c763bcd0a09b6cb1f703c7e4",
  },
  {
    id: 8,
    image: Cert6,
    title: "Cloud Solution Architecture",
    category: ["Cloud Computing"],
    url: "https://drive.google.com/file/d/12Z-Xiw9se4ZJxgkwRak80MpMgesb1LVV/view?usp=sharing",
  },
  {
    id: 9,
    image: Cert1,
    title: "Beginning Amazon Web Services course from 10Pearls University",
    category: ["Cloud Computing"],
    url: "https://10pearlsuniversity.org/view-certificate?cid=10PUC-b1dabedfe5579722d4ad6bc7c252d820a04ccd8c2c04d1b61805589",
  },
  {
    id: 10,
    image: Cert2,
    title: "Git Fundamentals",
    category: ["Git"],
    url: "https://10pearlsuniversity.org/view-certificate?cid=10PUC-8419e6b64edca640fe608ff34dc2832caf92fc6c2b2977b01802346",
  },
  {
    id: 11,
    image: Cert3,
    title: "Basics of Kubernetes",
    category: ["DevOps"],
    url: "https://10pearlsuniversity.org/view-certificate?cid=10PUC-2eabce0b659a88ae4fca86a73099319a9278e91c678ce2361805381",
  },
  {
    id: 12,
    image: Cert4,
    title: "Front-End Web Development with React",
    category: ["React"],
    url: "https://coursera.org/share/d3b79ecc01f69eee583ec0c643d6a046",
  },
];

export default Menu;
