import React, { useState } from "react";
import "./Portfolio.css";
import Modal from "../modal/Modal";
import Menu from "./Menu";
import { RiGithubLine, RiLink } from "react-icons/ri";

import { motion } from "framer-motion";

const Portfolio = () => {
  const [items, setItems] = useState(Menu);
  const [activeFilter, setActiveFilter] = useState("All");
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  // const filterItems = (categoryItem) => {
  //   const updatedItems = Menu.filter((curElem) => {
  //     return curElem.category.includes(categoryItem);
  //   });

  //   setItems(updatedItems);
  // };
  const filterItems = (companyFilter) => {
    const updatedItems = Menu.filter((curElem) => {
      return companyFilter === "All" || curElem.company === companyFilter;
    });

    setItems(updatedItems);
  };

  const openModal = (project) => {
    setSelectedProject(project);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedProject(null);
  };

  return (
    <section className="portfolio container section" id="portfolio">
      <h2 className="section__title">Projects</h2>

      {/* 
			<div className="portfolio__filters">
				<span className={activeFilter === 0 ? 'portfolio__item portfolio__item-active' : 'portfolio__item'} onClick={() => { setItems(Menu); setActiveFilter(0) }}>
					All
				</span>
				<span className={activeFilter === 1 ? 'portfolio__item portfolio__item-active' : 'portfolio__item'} onClick={() => { filterItems("Frontend"); setActiveFilter(1) }}>
					Frontend
				</span>
				<span className={activeFilter === 2 ? 'portfolio__item portfolio__item-active' : 'portfolio__item'} onClick={() => { filterItems("Backend"); setActiveFilter(2) }}>
					Backend
				</span>
				<span className={activeFilter === 3 ? 'portfolio__item portfolio__item-active' : 'portfolio__item'} onClick={() => { filterItems("Angular"); setActiveFilter(3) }}>
					Angular
				</span>
				<span className={activeFilter === 4 ? 'portfolio__item portfolio__item-active' : 'portfolio__item'} onClick={() => { filterItems("React"); setActiveFilter(4) }}>
					React
				</span>
			</div> */}

      <div className="portfolio__filters">
        <span
          className={
            activeFilter === "All"
              ? "portfolio__item portfolio__item-active"
              : "portfolio__item"
          }
          onClick={() => {
            filterItems("All");
            setActiveFilter("All");
          }}
        >
          All
        </span>
        <span
          className={
            activeFilter === "Individual"
              ? "portfolio__item portfolio__item-active"
              : "portfolio__item"
          }
          onClick={() => {
            filterItems("Individual");
            setActiveFilter("Individual");
          }}
        >
          Individual
        </span>
        <span
          className={
            activeFilter === "IrenicTech"
              ? "portfolio__item portfolio__item-active"
              : "portfolio__item"
          }
          onClick={() => {
            filterItems("IrenicTech");
            setActiveFilter("IrenicTech");
          }}
        >
          IrenicTech
        </span>
        <span
          className={
            activeFilter === "1 Ten Solutions"
              ? "portfolio__item portfolio__item-active"
              : "portfolio__item"
          }
          onClick={() => {
            filterItems("1 Ten Solutions");
            setActiveFilter("1 Ten Solutions");
          }}
        >
          1 Ten Solutions
        </span>
      </div>

      <div className="portfolio__container grid">
        {items.map((elem) => {
          const { id, image, title, category, url, techstack, repositoryUrl } =
            elem;

          return (
            <motion.div
              layout
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              className="portfolio__card"
              key={id}
            >
              <div
                className="portfolio__thumbnail"
                onClick={() => openModal(elem)}
              >
                <img
                  src={image}
                  alt=""
                  className="portfolio__img"
                  height="267"
                />
                <div className="portfolio__mask"></div>
              </div>

              <span className="portfolio__category">{category.join(", ")}</span>
              <h3 className="portfolio__title">{title}</h3>
              {url && (
                <a
                  href={url}
                  target="_blank"
                  rel="noreferrer"
                  className="portfolio__button"
                >
                  <RiLink className="portfolio__button-icon" />
                </a>
              )}
              {repositoryUrl && (
                <a
                  href={repositoryUrl}
                  target="_blank"
                  rel="noreferrer"
                  className="portfolio__github-button"
                >
                  <RiGithubLine className="portfolio__github-button-icon" />
                </a>
              )}
            </motion.div>
          );
        })}
      </div>
      {modalOpen && <Modal closeModal={closeModal} project={selectedProject} />}
    </section>
  );
};

export default Portfolio;
