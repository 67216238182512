import React, { useState } from "react";
import "./Certification.css";

import Menu from "./Menu";
import { RiGithubLine, RiLink } from "react-icons/ri";

import { motion } from "framer-motion";

const Certifications = () => {
  const [items, setItems] = useState(Menu);
  const [activeFilter, setActiveFilter] = useState("All");

  const filterItems = (categoryItem) => {
    const updatedItems = Menu.filter((curElem) => {
      return curElem.category.includes(categoryItem);
    });

    setItems(updatedItems);
  };

  return (
    <section className="portfolio container section" id="certifications">
      <h2 className="section__title">Certifications</h2>

      <div className="portfolio__filters">
        <span
          className={
            activeFilter === "All"
              ? "portfolio__item portfolio__item-active"
              : "portfolio__item"
          }
          onClick={() => {
            setItems(Menu);
            setActiveFilter("All");
          }}
        >
          All
        </span>
        <span
          className={
            activeFilter === "Blockchain"
              ? "portfolio__item portfolio__item-active"
              : "portfolio__item"
          }
          onClick={() => {
            filterItems("Blockchain");
            setActiveFilter("Blockchain");
          }}
        >
          Blockchain
        </span>
        <span
          className={
            activeFilter === "Generative AI"
              ? "portfolio__item portfolio__item-active"
              : "portfolio__item"
          }
          onClick={() => {
            filterItems("Generative AI");
            setActiveFilter("Generative AI");
          }}
        >
          Generative AI
        </span>
        <span
          className={
            activeFilter === "Cloud Computing"
              ? "portfolio__item portfolio__item-active"
              : "portfolio__item"
          }
          onClick={() => {
            filterItems("Cloud Computing");
            setActiveFilter("Cloud Computing");
          }}
        >
          Cloud Computing
        </span>
        <span
          className={
            activeFilter === "DevOps"
              ? "portfolio__item portfolio__item-active"
              : "portfolio__item"
          }
          onClick={() => {
            filterItems("DevOps");
            setActiveFilter("Kubernetes");
          }}
        >
          DevOps
        </span>
        <span
          className={
            activeFilter === "React"
              ? "portfolio__item portfolio__item-active"
              : "portfolio__item"
          }
          onClick={() => {
            filterItems("React");
            setActiveFilter("Kubernetes");
          }}
        >
          React
        </span>
        <span
          className={
            activeFilter === "Git"
              ? "portfolio__item portfolio__item-active"
              : "portfolio__item"
          }
          onClick={() => {
            filterItems("Git");
            setActiveFilter("Kubernetes");
          }}
        >
          Git
        </span>
      </div>

      <div className="portfolio__container grid">
        {items.map((elem) => {
          const { id, image, title, category, url, repositoryUrl } = elem;

          return (
            <motion.div
              layout
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              className="portfolio__card"
              key={id}
            >
              <div className="portfolio__thumbnail">
                <img
                  src={image}
                  alt=""
                  className="portfolio__img"
                  height="267"
                />
                <div className="portfolio__mask"></div>
              </div>

              <span className="portfolio__category">{category.join(", ")}</span>
              <h3 className="portfolio__title">{title}</h3>
              {url ? (
                <a
                  href={url}
                  target="_blank"
                  rel="noreferrer"
                  className="portfolio__button"
                >
                  <RiLink className="portfolio__button-icon" />
                </a>
              ) : (
                ""
              )}
              {repositoryUrl ? (
                <a
                  href={repositoryUrl}
                  target="_blank"
                  rel="noreferrer"
                  className="portfolio__github-button"
                >
                  <RiGithubLine className="portfolio__button-icon" />
                </a>
              ) : (
                ""
              )}
            </motion.div>
          );
        })}
      </div>
    </section>
  );
};

export default Certifications;
